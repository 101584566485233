<template>
  <div>
    <v-dialog persistent v-model="show" max-width="450px" max-height="800px">
      <v-card style="background-color: #f2f2f2">
        <v-card-title
          class="headline darken-1"
          style="background-color: #145c9f; color: white; height: auto"
        >
          <div class="d-flex px-1">
            <v-img height="80" width="80" src="@/assets/warning.svg"></v-img>
          </div>
          <div style="font-family: Roboto Slab" class="d-flex px-5">
            Are you sure want to <br />
            delete?
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            color="white"
            style="margin-top: -27%; margin-left: 94%"
            @click="toggleDeleteSchoolResourceModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="py-4">
          <v-row>
            <v-col class="px-3" cols="12">
              <p style="font-size: 16px">
                This is a irreversible action and once it's deleted, It'll be
                gone forever
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-0 pb-7 px-7">
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg dialogAction-btnText text-capitalize py-5 px-7"
            dark
            color="#D30024"
            :loading="formLoading"
            @click="deleteResource"
          >
            <span>Yes Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DeleteSchoolResourceModal",
  data() {
    return {
      formLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "campaignManagement/getSchoolResourceDelete",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleDeleteSchoolResourceModal({ show: value });
      },
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      toggleDeleteSchoolResourceModal:
        "campaignManagement/toggleDeleteSchoolResourceModal",
      showToast: "snackBar/showToast",
    }),
    deleteResource() {
      const self = this;
      self.formLoading = true;
      const successHandler = () => {
        self.formLoading = false;
        location.reload();
        self.toggleDeleteSchoolResourceModal({ Delete: false });
        this.showToast({
          message: "Record deleted",
          color: "s",
        });
      };
      const failureHandler = () => {
        self.formLoading = false;
      };
      let formData = {};
      console.log(formData);
      Axios.request_DELETE(
        "/school/school_resource/" +
          this.$store.state.campaignManagement.deleteSchoolResourceModal
            .schoolResourceID,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
};
</script>
